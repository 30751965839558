
.header {
  background-color: #fff;
  max-width: 1330px;
  margin: auto;
  padding: 0%;
}

.header_article {
  display: flex;
  width: 90%;
  margin: auto;
  align-items: center;
  position: relative;
}

.header_article_figure {
  flex: 0.2;
  width: 20%;
  cursor: pointer;
  padding: 1rem 0;
}

.header_article_nav {
  flex: 0.8;
  display: flex;
  gap: 3rem;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.header_article_figure_img {
  object-fit: contain;
  width: 100%;
}

.header_article_nav_link {
  font-size: 1.125rem;
  line-height: 1.688;
  color: #303030;
  text-decoration: none;
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
}

.icon {
  display: none;
}

.header_article_nav > a {
  color: #303030;
  font-size: 1.125rem;
  line-height: 1.688;
  text-decoration: none;
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
}

.header_article_nav_link,
.header_article_a {
  /* scale: 1; */
  transition: all 0.3s ease;
}

.header_article_nav_link:hover,
.header_article_a:hover {
  /* scale: 1.001; */
  text-decoration: underline;
}

.header_article_nav.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f1fafb;
  padding: 1rem;
  width: 100%;
  animation: navanim 1s ease 0s 1 normal forwards;
  z-index: 100;
}

.header_article_nav.open .header_article_nav_link {
  margin-bottom: 1rem;
}

.header_article_nav.open .header_article_nav_link:hover,
.header_article_nav.open .header_article_a:hover {
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #e9b44c;
  color: #fff;
  text-decoration: none;
}

.buttones {
  width: 160px;
  height: 50px;
  border-radius: 10px;
  color: #E9B44C;
  background-color: #fff;
  text-align: center;

  border-radius: 114.516px;
  background: rgba(242, 199, 115, 0.00);
}

.buttones > a {
  color: #000;
}

/* dropdown css */

.dropbtn {
  background-color: transparent;
  color: #000;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.dropbtn_span {
  display: flex;
  align-items: center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #64c8ce;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .header_article_nav_link_a {
  color: #e1e7ec;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  line-height: 1.5;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content { display: block; }

.dropbtn:hover {
  text-decoration: none;
}

.dropdown:hover .dropbtn { background-color: transparent; }

.dropdown-content .header_article_nav_link_a:hover { background-color: #24aab1e1; }

.glowing {
  animation: glow 2s infinite ease-in-out;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(233, 180, 76, 0.5), 0 0 10px rgba(233, 180, 76, 0.3);
  }
  50% {
    box-shadow: 0 0 15px rgba(233, 180, 76, 1), 0 0 30px rgba(233, 180, 76, 0.6);
  }
  100% {
    box-shadow: 0 0 5px rgba(233, 180, 76, 0.5), 0 0 10px rgba(233, 180, 76, 0.3);
  }
}

@media (min-width: 1280px) {
  .header_article {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .header_article_nav {
    display: none;
  }

  .header_article_figure_img {
    width: 100%;
  }

  .icon {
    display: block;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    left: 94%;
  }
}

@keyframes navanim {
  0% {
    opacity: 0;
    transform: rotateX(70deg);
    transform-origin: top;
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
    transform-origin: top;
  }
}

@media (max-width: 576px) {
  .header_article_figure {
    flex: 0.3;
  }
}
