.where-it-happens-section {
  width: 100%;
  margin: 0 auto;
  padding: clamp(40px, 10vw, 40px) clamp(16px, 5vw, 32px) 0px;
  font-family: Nunito, sans-serif;
}

.container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  align-items: center;
  gap: clamp(24px, 6vw, 24px);
}

.section-header {
  text-align: center;
  max-width: 800px;
}

.section-title {
  color: #023844;
  font-family: Nunito, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: clamp(32px, 5vw, 48px);
  font-weight: 700;
  line-height: 1.2;
  text-transform: capitalize;
  margin: 0 0 16px 0;
}

.section-subtitle {
  color: #e9b44b;
  font-family: 'Nunito', sans-serif;
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
}

/* Show/hide platforms based on screen size */
.desktop-platforms {
  display: grid;
}

.mobile-platforms {
  display: none;
}

/* Overlapping pyramid grid layout */
.platforms-container {
  display: grid;
  grid-template-areas:
    'center center'
    'left right';
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.platform-item {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  max-width: 800px;
  min-height: 400px;
  width: 100%;
  height: 100%;
}

.platform-link {
  display: block;
  text-decoration: none;
  color: inherit;
  height: 100%;
}

/* Hover effect only above 1114px */
@media (min-width: 1115px) {
  .platform-item:hover {
    transform: translateY(-10px);
  }

  .platform-item:hover .platform-image {
    transform: scale(1.0);
  }

  .platform-item:hover .platform-logo {
    transform: scale(1.0);
  }

  .platform-item:hover .platform-overlay {
    transform: translateY(-15%);
    padding-bottom: 20px !important;
    border-radius: 32px;
  }
}

/* Overlapping pyramid positioning */
.center-platform {
  grid-area: center;
  z-index: 1;
  justify-self: center;
  margin-bottom: 60px;
}

.left-platform {
  grid-area: left;
  justify-self: end;
  margin-right: 20px;
  z-index: 3;
  margin-top: -100px;
}

.right-platform {
  grid-area: right;
  justify-self: start;
  margin-left: 20px;
  z-index: 2;
  margin-top: -100px;
}

/* Update hover behavior to maintain overlap above 1114px */
@media (min-width: 1115px) {
  .center-platform:hover {
    transform: translateY(-10px);
    z-index: 4;
  }

  .left-platform:hover,
  .right-platform:hover {
    transform: translateY(-10px);
    z-index: 5;
  }
}

.platform-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
}

.platform-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.platform-logo {
  position: absolute;
  width: 117px;
  max-height: 128px;
  object-fit: contain;
  background: white;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: transform 0.3s ease;
}

.platform-logo.top-right {
  top: 0px;
  right: 0px;
}

.platform-logo.top-left {
  top: 0px;
  left: 0px;
}

.platform-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(219, 201, 32, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  padding: 0px 16px;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 40%;
}

.platform-title {
  color: white;
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 8px 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.platform-description {
  color: rgba(255, 255, 255, 0.9);
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

/* Responsive adjustments for overlapping pyramid */
@media (max-width: 1114px) {
  .desktop-platforms {
    display: none;
  }

  .mobile-platforms {
    display: grid;
  }

  .platforms-container {
    grid-template-areas:
      'center'
      'left'
      'right';
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .platform-item {
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 400px;
    margin: 0;
  }

  .left-platform {
    justify-self: center;
    order: 1;
    margin-top: 0;
    margin-right: 0;
  }

  .right-platform {
    justify-self: center;
    order: 2;
    margin-top: 0;
    margin-left: 0;
  }

  .center-platform {
    justify-self: center;
    order: 3 !important;
    margin-bottom: 0;
  }

  .second-item .platform-logo {
    left: 0;
    right: auto;
  }

  .platform-overlay {
    transform: translateY(0);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
  }

  .platform-item {
    transition: box-shadow 0.4s ease;
  }

  .platform-image {
    transition: none;
    object-fit: cover;
  }

  .platform-logo {
    transition: none;
  }

  .platform-overlay {
    transition: none;
  }

  .container {
    margin: 0%;
    padding: 0%;
  }
}

@media (max-width: 900px) {
  .platform-item {
    width: 100%;
    min-height: 250px;
  }
}

@media (max-width: 480px) {
  .platform-overlay {
    padding: 16px 12px;
  }

  .platform-title {
    font-size: 18px;
    margin-bottom: 4px;
  }

  .platform-description {
    font-size: 13px;
    line-height: 1.3;
  }

  .platform-logo {
    width: 60px;
    height: 60px;
    padding: 6px;
  }
}