.newsletter-section {
  display: flex;
  padding: 64px;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  font-family: Nunito, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #ffffff, rgba(224, 236, 238, 0.3));
}

@media (max-width: 991px) {
  .newsletter-section {
    padding: 40px 12px;
  }
}

.newsletter-content {
  display: flex;
  width: 100%;
  max-width: 1320px;
  flex-direction: column;
  align-items: center;
  color: rgba(2, 56, 68, 1);
  justify-content: center;
}

@media (max-width: 991px) {
  .newsletter-content {
    max-width: 100%;
  }
}

.newsletter-heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.96px;
  margin: 0;
}

@media (max-width: 991px) {
  .newsletter-heading {
    max-width: 100%;
    font-size: 32px;
  }
}

.newsletter-description {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  max-width: 600px;
}

@media (max-width: 991px) {
  .newsletter-description {
    max-width: 100%;
    padding: 0 10px;
    font-size: 18px;
    line-height: 1.5;
  }
}

/* New subscription form styles */
.subscription-form {
  display: flex;
  margin-top: 32px;
  max-width: 600px;
  width: 100%;
  border-radius: 115px;
  overflow: hidden;
  border: solid #D6D6D6 1px;
}

.email-input {
  flex: 1;
  /* border: 1px solid #e5e5e5; */
  border-radius: 115px 0 0 115px;
  padding: 16px 24px;
  font-size: 18px;
  color: #666;
  min-height: 56px;
  background-color: #fff;
  font-family: Nunito, -apple-system, Roboto, Helvetica, sans-serif;
}

.email-input:focus {
  outline: none;
  border-color: rgba(233, 180, 76, 0.5);
}

.subscribe-button {
  border: none;
  border-radius: 40px;
  background-color: rgba(233, 180, 76, 1);
  /* min-height: 56px; */
  padding: 18px 64px;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  letter-spacing: -0.66px;
  line-height: 1;
  cursor: pointer;
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
}

.subscribe-button:hover {
  background-color: #50a2a7;
}

.subscribe-button:focus {
  outline: 3px solid #50A2A7;
  outline-offset: 2px;
}

.subscribe-button.glowing {
  animation: glow 2s infinite ease-in-out;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(233, 180, 76, 0.5), 0 0 10px rgba(233, 180, 76, 0.3);
  }
  50% {
    box-shadow: 0 0 15px rgba(233, 180, 76, 1), 0 0 30px rgba(233, 180, 76, 0.6);
  }
  100% {
    box-shadow: 0 0 5px rgba(233, 180, 76, 0.5), 0 0 10px rgba(233, 180, 76, 0.3);
  }
}

@media (max-width: 991px) {
  .subscription-form {
    flex-direction: column;
    gap: 12px;
    width: 90%;
    border: none;
    border-radius: 0px;
  }
  
  .email-input {
    text-align: center;
    border-radius: 40px;
    border: solid 1px #D6D6D6;
  }
  
  .subscribe-button {
    border-radius: 40px;
    padding: 18px 64px;
    font-size: 18px;
  }
}