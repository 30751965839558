/* Hero Component Styles */
.hero {
  font-family: Nunito, -apple-system, Roboto, Helvetica, sans-serif;
  color: rgba(2, 56, 68, 1);
  background: linear-gradient(to bottom right, rgba(245, 250, 250, 1), rgba(224, 236, 238, 0.8));
  display: flex;
  width: 100%;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(233, 180, 76, 0.1) 0%, transparent 70%);
  opacity: 0.5;
  z-index: 0;
  pointer-events: none;
  animation: moveGradient 15s infinite linear;
}
@keyframes moveGradient {
  0% { transform: translate(0, 0); }
  50% { transform: translate(20%, 20%); }
  100% { transform: translate(0, 0); }
}
.hero-container {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  max-width: 1320px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}

.content-wrapper {
  display: flex;
  flex: 1;
  min-width: 300px;
  align-items: flex-start;
  justify-content: flex-start;
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 626px;
  align-items: flex-start;
  justify-content: flex-start;
}

.text-content {
  width: 100%;
}

.hero-heading {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
  letter-spacing: -1.12px;
  margin: 0;
}

.hero-subheading {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin: 8px 0 0;
}

.stats-section {
  display: flex;
  margin-top: 40px;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.stats-image {
  width: 132px;
  aspect-ratio: 2.54;
  object-fit: contain;
  object-position: center;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

.stats-number {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 40px;
  font-weight: 700;
}

.big-number {
  font-size: 40px;
  font-weight: 700;
  color: rgba(2, 56, 68, 1);
}

.stats-label {
  font-size: 20px;
  font-weight: 700;
}

.cta-section {
  margin-top: 40px;
  width: 100%;
}

.cta-button {
  border-radius: 115px;
  background-color: rgba(233, 180, 76, 1);
  min-height: 56px;
  padding: 18px 64px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.66px;
  font-family: Nunito, -apple-system, Roboto, Helvetica, sans-serif;
  position: relative;
  z-index: 1;
}

.cta-button.glowing {
  animation: glow 2s infinite ease-in-out;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(233, 180, 76, 0.5), 0 0 10px rgba(233, 180, 76, 0.3);
  }
  50% {
    box-shadow: 0 0 15px rgba(233, 180, 76, 1), 0 0 30px rgba(233, 180, 76, 0.6);
  }
  100% {
    box-shadow: 0 0 5px rgba(233, 180, 76, 0.5), 0 0 10px rgba(233, 180, 76, 0.3);
  }
}

.hero-image-wrapper {
  position: relative;
  width: 507px;
  max-width: 100%;
  aspect-ratio: 1.03;
  flex-shrink: 0;
  min-width: 300px;
}

.hero-image {
  width: 100%;
  height: auto;
}

/* Media Queries */
@media (max-width: 991px) {
  .hero {
    padding: 40px 20px;
  }
  .hero-container {
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .content-wrapper {
    min-width: 100%;
    justify-content: center;
  }
  .content-container {
    align-items: center;
    text-align: center;
    max-width: 100%;
  }
  .hero-heading {
    font-size: 40px;
    line-height: 48px;
  }
  .hero-subheading {
    font-size: 18px;
    line-height: 28px;
  }
  .stats-section {
    justify-content: center;
    gap: 12px;
    margin-top: 32px;
  }
  .stats-image {
    width: 98px;
  }
  .stats-number {
    font-size: 32px;
    gap: 4px;
  }
  .big-number {
    font-size: 32px;
  }
  .stats-label {
    font-size: 18px;
  }
  .cta-section {
    margin-top: 32px;
  }
  .cta-button {
    font-size: 20px;
  }
  .hero-image-wrapper {
    width: 100%;
    max-width: 400px;
    min-width: 240px;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 44px 21px 24px;
  }
  .hero-heading {
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.02rem;
  }
  .hero-subheading {
    font-size: 18px;
    line-height: 32px;
  }
  .stats-section {
    margin-top: 24px;
    gap: 3px;
  }
  .stats-image {
    width: 80px;
  }
  .stats-number {
    font-size: 24px;
    gap: 4px;
  }
  .big-number {
    font-size: 24px;
  }
  .stats-label {
    font-size: 16px;
  }
  .cta-section {
    margin-top: 24px;
  }
  .cta-button {
    font-size: 18px;
    min-height: 48px;
  }
  .hero-image-wrapper {
    max-width: 300px;
    min-width: 200px;
  }
}