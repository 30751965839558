.about-us-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom right, #ffffff, rgba(224, 236, 238, 0.3)); /* Subtle gradient to match HeroCom */
  width: 100%;
  padding: 64px;
  overflow: hidden;
  position: relative;
}

.about-us-header {
  width: 100%;
  font-family: Nunito, -apple-system, Roboto, Helvetica, sans-serif;
  color: #023844;
  max-width: 1320px;
}

.benefits-title {
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  text-align: left;
  letter-spacing: -0.96px;
  line-height: 1.2;
  margin: 0;
}

.benefits-subtitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  margin-top: 8px;
  margin-bottom: 0;
}

.benefits-container {
  display: flex;
  margin-top: 40px; /* Increased for better spacing */
  width: 100%;
  max-width: 1320px;
  align-items: stretch;
  gap: 32px;
  justify-content: center;
  flex-wrap: wrap;
}

.benefit-card {
  border-radius: 16px;
  border: 2px solid #e9b44c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 20px;
  flex: 1;
  min-width: 280px;
  max-width: 400px;
  min-height: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover */
}

.benefit-card:hover {
  transform: scale(1.05); /* Slight scale-up on hover */
  box-shadow: 0 0 15px rgba(233, 180, 76, 0.5); /* Glow effect on hover */
}

.benefit-card:focus {
  outline: 3px solid #50A2A7; /* Focus outline for accessibility */
  outline-offset: 2px;
}

.benefit-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.benefit-icon {
  aspect-ratio: 1.37;
  object-fit: contain;
  object-position: center;
  width: 164px;
  max-width: 100%;
}

.benefit-title {
  margin-top: 12px; /* Increased for better spacing */
  font-family: Raleway, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 24px;
  color: #31473a;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
}

.benefit-description {
  margin-top: 12px; /* Increased for better spacing */
  font-family: Nunito, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 20px;
  color: #023844;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
  margin-bottom: 0;
}

/* Responsive Styles */
@media (max-width: 991px) {
  .about-us-section {
    padding: 40px 20px;
  }

  .about-us-header {
    text-align: center;
  }

  .benefits-title {
    font-size: 40px;
    text-align: center;
  }

  .benefits-subtitle {
    font-size: 18px;
    line-height: 1.5;
  }

  .benefits-container {
    gap: 24px;
    margin-top: 32px; /* Adjusted for mobile */
  }

  .benefit-card {
    min-width: 260px;
    padding: 24px 16px;
    min-height: 280px;
  }

  .benefit-icon {
    width: 140px;
  }

  .benefit-title {
    font-size: 22px;
    margin-top: 10px;
  }

  .benefit-description {
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .about-us-section {
    padding: 32px 12px;
  }

  .benefits-title {
    font-size: 32px;
    line-height: 1.3;
  }

  .benefits-subtitle {
    font-size: 16px;
    line-height: 1.4;
  }

  .benefits-container {
    margin-top: 24px;
    gap: 16px;
  }

  .benefit-card {
    min-width: 100%;
    max-width: 100%;
    padding: 20px 12px;
    min-height: 260px;
  }

  .benefit-icon {
    width: 120px;
  }

  .benefit-title {
    font-size: 20px;
    margin-top: 8px;
  }

  .benefit-description {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
  }
}