.footer {
  background-color: #023844;
  padding: 2rem 0;
}

.footer_article {
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 1rem;
  overflow: hidden;
  max-width: 1330px;
  margin: auto;
}

.footer_article_figure {
  width: 50%;
}

.footer_article_figure_img {
  object-fit: contain;
  width: 100%;
}

.footer_article_nav {
  width: 100%;
  height: 100%;
}

.footer_article_nav_ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  list-style: none;
  gap: 2rem;
}

.footer_article_nav_ul_li1_h3,
.footer_article_nav_ul_li2_h3,
.footer_article_nav_ul_li3_h3,
.footer_article_nav_ul_li4_h3 {
  font-size: 1.125rem;
  line-height: 1.688;
  font-weight: 800;
  color: #fff;
  font-family: Nunito, sans-serif;
}

.footer_article_nav_ul_li {
  display: flex;
  flex-direction: column;
  font-family: Nunito, sans-serif;
}

.footer_article_nav_ul_li > a {
  font-size: 1.125rem;
  line-height: 1.688;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  font-family: Nunito, sans-serif;
}

.footer_insta_icon {
  color: #fa5420;
}

.footer_twitter_icon {
  color: #55acef;
}

.footer_linkedin_icon,
.footer_fb_icon {
  color: #0a66c2;
}

.hr_footer {
  color: #fff;
  width: 95%;
  margin: 2rem auto;
}

.copyright_footer {
  color: #fff;
  text-align: left;
  font-family: 'Nunito', sans-serif;
  width: 95%;
  padding-left: 2rem;
}

.copyright_footer > b {
  cursor: pointer;
  text-decoration: underline;
}

.footer_article_nav_ul_li_div {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
}

.footer_article_divs {
  display: flex;
  justify-content: space-between;
}

.footer_article_divs_app {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer_article_divs_app_p {
  color: #fff;
  font-size: 1rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0 0 0.2rem 0.3rem;
}

.footer_article_divs_app_p_divs {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 50px;
  margin: 0;
}

.footer_article_divs_app_p_divs > a {
  text-decoration: none;
}

.footer_article_divs_app_p_divs > a:hover {
  text-decoration: none;
}

/* modal css */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal_btn {
  cursor: pointer;
  text-decoration: underline;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 330px) {
  .footer_article_divs_app {
    padding-left: 0 !important;
  }
}

@media (max-width: 576px) {
  .footer_article_divs {
    flex-direction: column;
    gap: 1rem;
  }

  .footer_article_divs_app {
    padding-left: 0;
  }

  .footer_article_divs_app_p {
    margin-left: 10%;
    text-align: center;
  }

  .footer {
    overflow: hidden;
  }

  .footer_article_figure {
    margin: 0 auto;
  }

  .footer_article_nav_ul_li {
    align-items: center;
  }

  .footer_article_nav_ul_li_div {
    justify-content: center;
  }

  .footer_article_divs_app_p_divs {
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .footer_article_figure {
    width: 25%;
  }

  .footer_article,
  .copyright_footer {
    width: 85%;
  }

  /*
  .footer {
    height: 53rem;
  } */

  .footer_article_nav_ul_li {
    gap: 1rem;
  }
}

/* @media (min-width: 624px) {
  .footer {
    height: 37rem;
  }
} */

@media (min-width: 1124px) {
  .footer_article_figure {
    width: 15%;
  }

  .footer_article,
  .copyright_footer {
    width: 75%;
  }

  /* .footer {
    height: 33rem;
  } */
}

@media (max-width: 760px) {
  .footer_article_nav_ul_li_div {
    align-items: center;
    justify-content: center;
  }

  .footer_article_nav_ul_li {
    text-align: center;
  }
}